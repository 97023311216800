import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PageHeader from '../components/pageHeader.js'
import ReactGA from 'react-ga'

import {
  humanTime,
  mapArray,
  mapArrayNoOxford,
  naturalSort,
} from '../helpers.js'
import CollectionBuilder from '../components/categoryWrapper.js'
import Footer from '../components/footer.js'
import Dotdotdot from 'react-dotdotdot'
import SVG from 'react-inlinesvg'
import { BookMetadataHeader } from '../components/metadataHeader.js'

import page from '../css/modules/bookPage.module.scss'
import clip from '../css/modules/clipList.module.scss'

import playIcon from '../static/icons/PlaySVG.svg'
import playingIcon from '../static/icons/volumeOn.svg'
import loadingIcon from '../static/icons/loading.svg'
import playSans from '../static/icons/playSans.svg'

class BookPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      backColor: '',
      loading: true,
      randomGenre: 0,
    }
    this.mediaClick = this.mediaClick.bind(this)
    this.mediaClickAll = this.mediaClickAll.bind(this)
  }

  componentWillMount() {
    // let randomGenre = Math.floor(
    //   Math.random() * this.props.data.bookJson.genre.length
    // )

    // Randomization not working; just use first genre
    let randomGenre = 0
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false })
    }, 1)
  }

  books() {
    return this.props.data.bookJson
  }

  mediaClick(e) {
    this.mediaClickAll(e, this.books().clip.indexOf(e))
  }

  mediaClickAll(e, singleClip = false) {
    let sourceNum = singleClip ? singleClip : 0
    let mediaInfo = {
      image: this.books().image,
      book_author: mapArray(this.books().book_author),
      title: e.title || this.books().title,
    }
    this.props.onClick(this.books().clip, mediaInfo, sourceNum)
  }

  authorLink() {
    let authors = []

    this.books().book_author.map((author, i) => {
      authors.push(
        author.author_link ? (
          <a key={i} className={page.nameLink} href={author.author_link}>
            {author.title}
          </a>
        ) : (
          <span key={i}>{author.title}</span>
        )
      )
      authors.push(
        this.books().book_author.length === 2 && i === 0
          ? ' and '
          : this.books().book_author.length === i + 2
          ? ', and '
          : this.books().book_author.length === i + 1
          ? ''
          : ', '
      )
    })
    return authors
  }

  render() {
    return (
      <div
        style={{
          opacity: this.state.loading ? '0' : '1',
          transition: 'opacity 0.1s',
        }}>
        <BookMetadataHeader
          book={this.books()}
          canonicalUrl={this.props.data.site.siteMetadata.siteUrl}
        />
        <PageHeader
          searchQuery={this.props.searchQuery}
          history={this.props.history}
          pageTitle={this.books().title}
        />
        <div className={page.container}>
          <div className={page.background}>
            {this.books().image ? (
              <img alt="" src={this.books().image} className={page.blurbg} />
            ) : null}
            <img
              alt={`Cover of ${this.books().title}`}
              className={page.image}
              src={this.books().image}
            />
          </div>
          <div
            onClick={this.mediaClickAll}
            className={`bold-header ${page.playAll}`}>
            <SVG className={page.playIcon} src={playSans} />
            <span>Play all</span>
          </div>

          <div className={page.summary}>
            <h1 className={`bold-header ${page.title}`}>
              {this.books().title}
            </h1>
            <p className={page.name}>By {this.authorLink()}</p>
            <div className={page.narratorContainer}>
              {this.books().narrator.length > 0 ? (
                <p className={page.narrator}>
                  Narrated by {mapArray(this.books().narrator)}
                </p>
              ) : (
                ''
              )}
              {this.books().reading_age.length > 0 ? (
                <p className={page.ages}>
                  Suitable for {mapArray(this.books().reading_age)}
                </p>
              ) : (
                ''
              )}
              {this.books().publisher.length > 0 ? (
                <p className={page.publisher}>
                  Published by {mapArray(this.books().publisher)}
                </p>
              ) : (
                ''
              )}
            </div>
            {this.books().book_synopsis ? (
              <p className={page.synopsis}>{this.books().book_synopsis}</p>
            ) : (
              ''
            )}
          </div>
        </div>
        <ClipList
          onPlay={this.props.onPlay}
          mediaPlaying={this.props.mediaPlaying}
          onClick={this.mediaClick}
          sources={this.props.sources}
          sourceNum={this.props.sourceNum}
          data={this.books().clip}
          bookImage={this.books().image}
        />
        {this.books().genre.length > 0 ? (
          <CollectionBuilder
            bookpage={true}
            wrappers={[
              {
                property: this.books().genre[this.state.randomGenre].title,
                title: this.books().genre[this.state.randomGenre].title,
                type: 'genre',
              },
            ]}
            data={this.props.data}
          />
        ) : (
          ''
        )}
        <Footer />
      </div>
    )
  }
}

class ClipList extends React.Component {
  constructor(props) {
    super(props)
    this.mediaClick = this.mediaClick.bind(this)
  }

  mediaClick(e) {
    this.props.onClick(e)
  }

  createClipList() {
    let clipCards = []
    this.props.data.sort(naturalSort('title'))
    for (var i = 0; i < this.props.data.length; i++) {
      clipCards.push(
        <ClipCard
          bookImage={this.props.bookImage}
          onPlay={this.props.onPlay}
          mediaPlaying={this.props.mediaPlaying}
          sources={this.props.sources}
          sourceNum={this.props.sourceNum}
          onClick={this.mediaClick}
          key={i}
          index={i + 1}
          data={this.props.data[i]}
        />
      )
    }

    return clipCards
  }

  render() {
    return (
      <div className={clip.wrapper}>
        <ul>{this.createClipList()}</ul>
      </div>
    )
  }
}

class ClipCard extends React.Component {
  constructor(props) {
    super(props)
    this.mediaClick = this.mediaClick.bind(this)
    this.state = {
      currentIcon: '',
    }
  }

  mediaClick(e) {
    this.props.onClick(this.props.data)
    if (
      this.props.sources &&
      this.props.sources[this.props.sourceNum].src === this.props.data.clip_link
    )
      return
    ReactGA.ga('send', 'event', 'Audio Play', `${this.props.data.title}`)
  }

  render() {
    const clipImage = this.props.data.clip_image
      ? this.props.data.clip_image
      : this.props.bookImage
    const clipMediaIcon =
      this.props.sources &&
      this.props.sources[this.props.sourceNum].src === this.props.data.clip_link
        ? !this.props.onPlay
          ? loadingIcon
          : this.props.mediaPlaying
          ? playingIcon
          : playIcon
        : playIcon
    const clipTitlePlayStatus =
      this.props.sources &&
      this.props.sources[this.props.sourceNum].src === this.props.data.clip_link
        ? clip.clipActive
        : clip.clip
    const clipMediaIconPlayStatus =
      this.props.sources &&
      this.props.sources[this.props.sourceNum].src ===
        this.props.data.clip_link &&
      this.props.mediaPlaying
        ? clip.playingIcon
        : clip.playIcon
    const clipLinkPlayStatus = this.props.sources
      ? this.props.sources[this.props.sourceNum].src ===
        this.props.data.clip_link
        ? clip.active
        : clip.play
      : clip.play

    return (
      <li onClick={this.mediaClick} className={clipTitlePlayStatus}>
        <div className={clip.container}>
          <div className={clipMediaIconPlayStatus}>
            <SVG
              ref={this.svgRef}
              onHover={this.handleHover}
              src={clipMediaIcon}
            />
          </div>
          <a className={clipLinkPlayStatus}>
            <span className={clip.title}>{this.props.data.title}</span>
          </a>
          <span className={clip.mobileDuration}>
            {humanTime(this.props.data.duration)}
          </span>
          <span className={clip.duration}>
            {humanTime(this.props.data.duration)}
          </span>
          <span className={clip.synopsis}>
            <Dotdotdot clamp={5}>
              <span>{this.props.data.clip_synopsis}</span>
            </Dotdotdot>
          </span>
        </div>
        <div className={clip.imageContainer}>
          <div className={clip.item}>
            <div className={clip.background}>
              <img alt="" src={clipImage} className={clip.blurbg} />
              <img
                alt={`Cover of ${this.props.data.title}`}
                src={clipImage}
                className={clip.blurImage}
              />
            </div>
          </div>
        </div>
      </li>
    )
  }
}

export default BookPage

export const query = graphql`
  query BookPageQuery($id: String) {
    bookJson(id: { eq: $id }) {
      publisher {
        title
      }
      narrator {
        title
      }
      book_author {
        title
        slug
        author_link
      }
      clip {
        title
        duration
        clip_link
        clip_synopsis
        clip_image
      }
      gatsby_slug
      image
      title
      book_synopsis
      genre {
        title
      }
      illustrator
      reading_age {
        title
      }
    }
    allBookJson {
      edges {
        node {
          id
          slug
          gatsby_slug
          date
          book_author {
            title
          }
          title
          image
          genre {
            title
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
